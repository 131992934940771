<template>
  <TheTitlebar />
  <div class="view__container">
    <router-view :key="$route.fullPath" />
  </div>
  <TheFooter />
</template>

<script>
import TheTitlebar from "@/components/TheTitlebar";
import TheFooter from "@/components/TheFooter";
import axios from "axios";

export default {
  components: {
    TheTitlebar,
    TheFooter
  },
  mounted() {
    axios.defaults.headers.common["Authorization"] =
      "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiIsImtpZCI6Im5ldHdvcmstcHVibGlzaGVyLXRva2VuIn0.eyJpc3MiOiJodHRwczpcL1wvYXBpLmp1bXBmZWVkLmNvbSIsImlhdCI6MTcwNzkyNTQ5MiwiZXhwIjozMjg1ODQ4NjkyLCJzY29wZSI6WyJjcmVhdGUiLCJyZWFkIiwidXBkYXRlIiwiZGVsZXRlIiwibGlzdCIsImFsbCJdLCJpcCI6IjE5Mi4xNjguNjUuMSIsInB1Ymxpc2hlcl9hY2NvdW50Ijoie1wiaWRcIjpcIjEwMDAxXCIsXCJidXNpbmVzc19pZFwiOlwiMTVcIixcIm5hbWVcIjpcIkp1bXBmZWVkIFB1Ymxpc2hlclwiLFwicGF5b3V0X3ZhbHVlX3JldGFpbl9wZXJjZW50YWdlXCI6XCIxMDBcIixcInRpbWVfY3JlYXRlZFwiOlwiMjAyMy0wMS0wNiAxODozMDowMVwiLFwidGltZV91cGRhdGVkXCI6bnVsbCxcImRlbGV0ZWRcIjpcIjBcIixcInRpbWVfZGVsZXRlZFwiOm51bGwsXCJkb21haW5cIjpcImNhcnNjaWVuY2UuY29tXCIsXCJwdWJsaXNoZXJfYWNjb3VudF9kb21haW5faWRcIjpcIjdcIn0ifQ.btUtWpqcArLi0bmQt5FnW4GFZYjAkbQqr9ozjtpMG-0";
  },
  methods: {}
};
</script>

<style lang="scss">
#app {
  background: color(background);

  .view__container {
    @include full-screen-height;

    margin-top: $titlebar-height;
  }
}
</style>
