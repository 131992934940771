import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";
import axios from "axios";

const routes = [
  {
    path: "/:pathMatch(.*)*",
    name: "404",
    component: () => import("@/views/ErrorScreen")
  },
  {
    path: "/",
    name: "lander",
    component: () => import("@/views/Lander")
  },
  {
    path: "/search",
    name: "srp",
    component: () => import("@/views/Srp")
  },
  {
    path: "/listing/:productId",
    name: "listing",
    component: () => import("@/views/Listing")
  },
  {
    path: "/contact",
    name: "contact",
    beforeEnter(to, from, next) {
      next(from.path);
      window.location.replace("mailto:" + store.state.brand.contactEmail);
      if (from.matched.length === 0) {
        window.location.replace("https://" + store.state.brand.domain);
      }
    }
  },
  {
    path: "/privacy-policy",
    name: "privacyPolicy",
    component: () => import("@/views/PrivacyPolicy")
  },
  {
    path: "/terms-of-use",
    name: "termsOfUse",
    component: () => import("@/views/TermsOfUse")
  },
  {
    path: "/ccpa-opt-out",
    name: "ccpaOptOut",
    beforeEnter() {
      window.open("https://search.carscience.com/ccpa.php", "_blank");
      return false;
    }
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  if (Object.prototype.hasOwnProperty.call(to.query, "utm_campaign")) {
    sessionStorage.setItem(
      "carscience-session-utm-campaign-id",
      to.query.utm_campaign
    );
  }
  if (
    sessionStorage.getItem("carscience-session-utm-campaign-id") !== null &&
    !Object.prototype.hasOwnProperty.call(to.query, "utm_campaign")
  ) {
    to.query.utm_campaign = sessionStorage.getItem(
      "carscience-session-utm-campaign-id"
    );
    next({ path: to.path, query: to.query });
    return;
  }

  axios.defaults.headers.common["Authorization"] =
    "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiIsImtpZCI6Im5ldHdvcmstcHVibGlzaGVyLXRva2VuIn0.eyJpc3MiOiJodHRwczpcL1wvYXBpLmp1bXBmZWVkLmNvbSIsImlhdCI6MTcwNzkyNTQ5MiwiZXhwIjozMjg1ODQ4NjkyLCJzY29wZSI6WyJjcmVhdGUiLCJyZWFkIiwidXBkYXRlIiwiZGVsZXRlIiwibGlzdCIsImFsbCJdLCJpcCI6IjE5Mi4xNjguNjUuMSIsInB1Ymxpc2hlcl9hY2NvdW50Ijoie1wiaWRcIjpcIjEwMDAxXCIsXCJidXNpbmVzc19pZFwiOlwiMTVcIixcIm5hbWVcIjpcIkp1bXBmZWVkIFB1Ymxpc2hlclwiLFwicGF5b3V0X3ZhbHVlX3JldGFpbl9wZXJjZW50YWdlXCI6XCIxMDBcIixcInRpbWVfY3JlYXRlZFwiOlwiMjAyMy0wMS0wNiAxODozMDowMVwiLFwidGltZV91cGRhdGVkXCI6bnVsbCxcImRlbGV0ZWRcIjpcIjBcIixcInRpbWVfZGVsZXRlZFwiOm51bGwsXCJkb21haW5cIjpcImNhcnNjaWVuY2UuY29tXCIsXCJwdWJsaXNoZXJfYWNjb3VudF9kb21haW5faWRcIjpcIjdcIn0ifQ.btUtWpqcArLi0bmQt5FnW4GFZYjAkbQqr9ozjtpMG-0";

  let promiseChain = [];
  if (!store.state.publisher.jfpcScriptAttached) {
    promiseChain.push(store.dispatch("publisher/ATTACH_JFPC_SCRIPT"));
  }
  if (!store.getters["geolocation/isSet"]) {
    promiseChain.push(store.dispatch("geolocation/GET_REQUEST"));
  }
  if (!store.getters["vehicleMakeModels/isSet"]) {
    promiseChain.push(store.dispatch("vehicleMakeModels/GET_REQUEST"));
  }

  Promise.all(promiseChain).then(() => {
    if (
      typeof store.state.geolocation.country === "string" &&
      store.state.geolocation.country === "US" &&
      typeof store.state.geolocation.postalCode === "string" &&
      store.state.geolocation.postalCode.length === 5
    ) {
      if (!store.getters["search/locationSet"]) {
        store.commit(
          "search/SET_LOCATION",
          store.getters["geolocation/searchLocationObject"]
        );
      }
    }

    if (to.path === "/search") {
      if (Object.prototype.hasOwnProperty.call(to.query, "query")) {
        store.commit("search/SET_STATE_FROM_QUERY_PARAM", to.query.query);
      }
      store.commit("search/SET_LOADING");
    }
    next();
  });
});

router.afterEach(() => {
  window.scrollTo({ top: 0, behavior: "instant" });
});

export default router;
