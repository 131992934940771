/* eslint-disable no-undef */

export default {
  data() {
    return {};
  },
  created() {},
  unmounted() {},
  methods: {
    fireViewContentEvents(eventValue, itemIds = [], facebookCustomData = null) {
      return new Promise(resolve => {
        let eventName = "ViewContent";

        this.fireFacebookPixel(
          eventName,
          facebookCustomData
            ? facebookCustomData
            : {
                value: eventValue,
                currency: "USD"
              }
        );

        this.fireTiktokPixel("ViewContent", eventValue);

        this.fireTaboolaPixel("view_content", eventValue);

        this.fireGoogleTagPixel("viewContent", eventValue, itemIds).then(() => {
          resolve();
        });
      });
    },
    fireFacebookPixel(eventName, eventData) {
      try {
        fbq("track", eventName, eventData);
      } catch (e) {
        console.log("Facebook Pixel Fire Error: ");
        console.log(e);
      }
    },
    fireGoogleTagPixel(eventName, eventValue, itemIds = []) {
      return new Promise(resolve => {
        let googleTagData = {
          awId: "AW-330698030",
          uaId: "UA-163416254-7",
          conversionLabels: {
            viewContent: "kKZ7CLbBt9wCEK6a2J0B",
            lead: "dK1ECMbRt9wCEK6a2J0B"
          }
        };

        let gtagEventData = {
          send_to:
            googleTagData.awId +
            "/" +
            googleTagData.conversionLabels[eventName],
          value: eventValue,
          currency: "USD",
          event_callback: id => {
            if (id.includes("AW-")) {
              resolve();
            }
          }
        };

        if (itemIds.length) {
          gtagEventData["dynx_itemid"] = itemIds;
        }

        try {
          gtag("event", "conversion", gtagEventData);
        } catch (e) {
          console.log("Google Tag Pixel Fire Error: ");
          console.log(e);
          resolve();
        }
      });
    },
    fireTaboolaPixel(eventName, eventValue) {
      try {
        _tfa.push({
          notify: "event",
          name: eventName,
          id: 1296227,
          revenue: eventValue
        });
      } catch (e) {
        console.log("Taboola Pixel Fire Error: ");
        console.log(e);
      }
    },
    fireTiktokPixel(eventName, eventValue) {
      try {
        ttq.track(eventName, {
          value: eventValue,
          currency: "USD"
        });
      } catch (e) {
        console.log("TikTok Pixel Fire Error: ");
        console.log(e);
      }
    }
  }
};
