import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Quasar from 'quasar/src/vue-plugin.js';;
import quasarUserOptions from "./quasar-user-options";
import mixins from "./mixins";

createApp(App)
  .use(Quasar, quasarUserOptions)
  .use(store)
  .use(router)
  .mixin(mixins)
  .mount("#app");

import moment from "moment";
moment.locale("en");
// app.config.globalProperties.$moment = moment;
