/* eslint-disable no-unused-vars */
import moment from "moment";
moment.locale("en");
import { reactive } from "@vue/reactivity";
import LZString from "lz-string";

import {
  // NAVIGATE_TO_SEARCH_PAGE,
  CHECK_FILTER_MODELS_UPDATED,
  CHECK_FILTER_MODELS_DEFAULTED,
  SET_STATE_FROM_QUERY_PARAM,
  SET_LOADING,
  SET_LOADED,
  SET_PAGE,
  SET_PAGINATION_TOTALS,
  SET_SORT,
  SET_URL_PARAMS,
  SET_FILTER_MODELS_ACTIVE,
  SET_KEYWORD,
  SET_LOCATION,
  SET_FILTER_OPTIONS,
  POPULATE_FILTER_OPTIONS,
  REMOVE_FILTER_MODEL_VALUE,
  RESET_FILTERS,
  RESET_PAGINATION,
  RESET_KEYWORD
} from "@/store/actions/search";
import mixins from "@/mixins";
import axios from "axios";

const state = reactive({
  loaded: false,
  location: {
    radius: window.localStorage.getItem(
      window.location.hostname + "-searchLocationRadius"
    )
      ? window.localStorage.getItem(
          window.location.hostname + "-searchLocationRadius"
        )
      : 100,
    postalCode: window.localStorage.getItem(
      window.location.hostname + "-searchLocationPostalCode"
    )
      ? window.localStorage.getItem(
          window.location.hostname + "-searchLocationPostalCode"
        )
      : "",
    city: window.localStorage.getItem(
      window.location.hostname + "-searchLocationCity"
    )
      ? window.localStorage.getItem(
          window.location.hostname + "-searchLocationCity"
        )
      : "",
    state: window.localStorage.getItem(
      window.location.hostname + "-searchLocationState"
    )
      ? window.localStorage.getItem(
          window.location.hostname + "-searchLocationState"
        )
      : ""
  },
  pagination: {
    currentPage: 1,
    pageSelectModel: 1,
    totalResults: 0,
    totalPages: 500,
    perPage: 20,
    disableNext: false,
    disablePrevious: true,
    disableAll: false
  },
  sort: {
    model: "advanced.popular_on_carscience",
    options: {
      classic: [
        {
          label: "Highest Mileage",
          value: "classic.highest_mileage",
          searchObjects: [{ mileage: "desc" }]
        },
        {
          label: "Lowest Mileage",
          value: "classic.lowest_mileage",
          searchObjects: [{ mileage: "asc" }]
        },
        {
          label: "Highest Price",
          value: "classic.highest_price",
          searchObjects: [{ price: "desc" }]
        },
        {
          label: "Lowest Price",
          value: "classic.lowest_price",
          searchObjects: [{ price: "asc" }]
        },
        {
          label: "Newest Year",
          value: "classic.newest_year",
          searchObjects: [{ year: "desc" }]
        },
        {
          label: "Oldest Year",
          value: "classic.oldest_year",
          searchObjects: [{ year: "asc" }]
        },
        {
          label: "Newest Listed",
          value: "classic.newest_listed",
          searchObjects: [{ id: "desc" }]
        },
        {
          label: "Oldest Listed",
          value: "classic.oldest_listed",
          searchObjects: [{ id: "asc" }]
        },
        {
          label: "Nearest Location",
          value: "classic.nearest_location",
          searchObjects: [{ distance: "asc" }]
        }
      ],
      advanced: [
        /*{
          label: "Recommended",
          value: "advanced.recommended",
          searchObjects: [{ bid_value: "desc" }, { product_id: "desc" }]
        },*/
        {
          label: "Popular On CarScience",
          value: "advanced.popular_on_carscience",
          searchObjects: [
            { "product_performance_rollup.last_365_clicks": "desc" }
          ]
        },
        {
          label: "Most Searched",
          value: "advanced.most_searched",
          searchObjects: [
            { "product_performance_rollup.last_365_impressions": "desc" }
          ]
        },
        {
          label: "Recent Price Drops",
          value: "advanced.recent_price_drops",
          searchObjects: [{ "tags.time_created": "desc" }]
        },
        {
          label: "Biggest Price Drops",
          value: "advanced.biggest_price_drops",
          searchObjects: [{ "tags.metadata.percent_change": "desc" }]
        }
      ]
    }
  },
  filter: {
    active: 0,
    modelsActive: {
      bodyType: [],
      makeId: [],
      modelId: [],
      year: {
        min: null,
        max: null
      },
      price: {
        min: null,
        max: null
      },
      mileage: {
        min: null,
        max: null
      },
      exteriorColor: [],
      condition: [],
      fuelType: [],
      transmission: [],
      engineType: [],
      drivetrain: []
    },
    models: {
      bodyType: [],
      makeId: [],
      modelId: [],
      year: {
        min: null,
        max: null
      },
      price: {
        min: null,
        max: null
      },
      mileage: {
        min: null,
        max: null
      },
      exteriorColor: [],
      condition: [],
      fuelType: [],
      transmission: [],
      engineType: [],
      drivetrain: []
    },
    modelDefaults: {
      bodyType: [],
      makeId: [],
      modelId: [],
      year: {
        min: null,
        max: null
      },
      price: {
        min: null,
        max: null
      },
      mileage: {
        min: null,
        max: null
      },
      exteriorColor: [],
      condition: [],
      fuelType: [],
      transmission: [],
      engineType: [],
      drivetrain: []
    },
    options: {
      makeId: [],
      modelId: [],
      makeModelsNested: [],
      condition: [],
      bodyType: [],
      exteriorColor: [],
      fuelType: [],
      transmission: [],
      engineType: [],
      drivetrain: [],
      locationRadius: [
        { label: "10 miles", value: "10" },
        { label: "25 miles", value: "25" },
        { label: "50 miles", value: "50" },
        { label: "75 miles", value: "75" },
        { label: "100 miles", value: "100" },
        { label: "150 miles", value: "150" },
        { label: "200 miles", value: "200" },
        { label: "250 miles", value: "250" },
        { label: "500 miles", value: "500" }
      ],
      year: {
        min: null,
        max: null
      },
      price: {
        min: null,
        max: null
      },
      mileage: {
        min: null,
        max: null
      }
    },
    optionsDefaults: {
      makeId: [],
      modelId: [],
      makeModelsNested: [],
      condition: [],
      bodyType: [],
      exteriorColor: [],
      fuelType: [],
      transmission: [],
      engineType: [],
      drivetrain: []
    }
  },
  keyword: ""
});

const getters = {
  currentPaginationRangeBlurb: state => condensed => {
    let resultsLow =
      state.pagination.totalResults === 0
        ? 0
        : (state.pagination.currentPage - 1) * state.pagination.perPage + 1;
    let resultsHigh =
      state.pagination.currentPage * state.pagination.perPage >
      state.pagination.totalResults
        ? state.pagination.totalResults
        : state.pagination.currentPage * state.pagination.perPage;
    if (condensed) {
      return (
        mixins.methods.formatNumber(resultsLow) +
        " to " +
        mixins.methods.formatNumber(resultsHigh) +
        " of " +
        mixins.methods.formatNumber(state.pagination.totalResults)
      );
    } else {
      return (
        "Showing " +
        mixins.methods.formatNumber(resultsLow) +
        " to " +
        mixins.methods.formatNumber(resultsHigh) +
        " of " +
        mixins.methods.formatNumber(state.pagination.totalResults) +
        " Vehicles"
      );
    }
  },
  activeSortObject: state => {
    let currentSortType = state.sort.model.split(".")[0];
    return state.sort.options[currentSortType].filter(
      sortOption => sortOption.value === state.sort.model
    )[0];
  },
  locationSet: state => {
    return (
      state.location.postalCode.length > 0 &&
      state.location.city.length > 0 &&
      state.location.state.length > 0
    );
  },
  locationCityStateFormatted: state => {
    if (state.location.city.length > 0 && state.location.state.length > 0) {
      return state.location.city + ", " + state.location.state;
    }

    return "Nationwide";
  }
};

const actions = {
  [CHECK_FILTER_MODELS_UPDATED]: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      if (
        JSON.stringify(state.filter.modelsActive) ===
        JSON.stringify(state.filter.models)
      ) {
        resolve(false);
      } else {
        resolve(true);
      }
    });
  },
  [CHECK_FILTER_MODELS_DEFAULTED]: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      if (
        JSON.stringify(state.filter.modelDefaults) ===
        JSON.stringify(state.filter.models)
      ) {
        resolve(true);
      } else {
        resolve(false);
      }
    });
  },
  [POPULATE_FILTER_OPTIONS]: (
    { commit, dispatch },
    { filterParams = {}, includeLocationParam = false }
  ) => {
    return new Promise((resolve, reject) => {
      let searchParams = {
        filters: filterParams,
        options: {
          deduplicate_field: ["vin"],
          faceting: {
            fields: {
              condition: [],
              make_id: [],
              model_id: [],
              body_type: [],
              exterior_color: [],
              fuel_type: [],
              engine_type: [],
              transmission: [],
              price: ["range"],
              mileage: ["range"],
              year: ["range"]
            },
            size: 2000
          }
        },
        pagination: {
          page: 1,
          per_page: 1
        }
      };

      if (includeLocationParam) {
        searchParams.options.location_search = {
          postal_code: state.location.postalCode,
          radius: state.location.radius,
          include_distance_field: true
        };
      }

      axios
        .post(
          "https://api-v2.jumpfeed.com/network/publisher/products/vehicles",
          searchParams
        )
        .then(resp => {
          let esSearchFacets = resp.data.metadata.facets;
          if (esSearchFacets) {
            commit(SET_FILTER_OPTIONS, esSearchFacets);
          }
          resolve();
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  }
};

const mutations = {
  [SET_STATE_FROM_QUERY_PARAM]: (state, queryParam) => {
    let existingUrlParamsState = null;
    try {
      existingUrlParamsState = JSON.parse(
        LZString.decompressFromEncodedURIComponent(queryParam)
      );
      if (
        existingUrlParamsState == null ||
        typeof existingUrlParamsState !== "object" ||
        !("pagination" in existingUrlParamsState) ||
        !("location" in existingUrlParamsState) ||
        !("sort" in existingUrlParamsState) ||
        !("filter" in existingUrlParamsState) ||
        !("keyword" in existingUrlParamsState)
      ) {
        existingUrlParamsState = null;
      }
    } catch (e) {
      existingUrlParamsState = null;
    }
    if (existingUrlParamsState !== null) {
      state.location = JSON.parse(
        JSON.stringify(existingUrlParamsState.location)
      );
      state.pagination = JSON.parse(
        JSON.stringify(existingUrlParamsState.pagination)
      );
      state.sort.model = JSON.parse(
        JSON.stringify(existingUrlParamsState.sort.model)
      );
      state.filter.models = JSON.parse(
        JSON.stringify(existingUrlParamsState.filter.modelsActive)
      );
      state.filter.modelsActive = JSON.parse(
        JSON.stringify(existingUrlParamsState.filter.modelsActive)
      );
      state.keyword = JSON.parse(
        JSON.stringify(existingUrlParamsState.keyword)
      );
    }
  },
  [SET_LOADING]: state => {
    state.loaded = false;
    state.pagination.disableAll = true;
  },
  [SET_LOADED]: state => {
    state.loaded = true;
    state.pagination.disableAll = false;
  },
  [SET_URL_PARAMS]: state => {
    let urlParams = new URLSearchParams(window.location.search);
    urlParams.set(
      "query",
      LZString.compressToEncodedURIComponent(
        JSON.stringify({
          location: state.location,
          pagination: state.pagination,
          sort: {
            model: state.sort.model
          },
          filter: {
            modelsActive: state.filter.modelsActive
          },
          keyword: state.keyword
        })
      )
    );

    window.history.replaceState(
      history.state,
      null,
      "?" + urlParams.toString()
    );
  },
  [SET_PAGE]: (state, page) => {
    if (
      page === "next" &&
      state.pagination.currentPage !== state.pagination.totalPages
    ) {
      ++state.pagination.currentPage;
    } else if (
      page === "last" &&
      state.pagination.currentPage !== state.pagination.totalPages
    ) {
      state.pagination.currentPage = state.pagination.totalPages;
    } else if (page === "previous" && state.pagination.currentPage !== 1) {
      --state.pagination.currentPage;
    } else if (page === "first" && state.pagination.currentPage !== 1) {
      state.pagination.currentPage = 1;
    } else {
      state.pagination.currentPage = page;
    }

    state.pagination.disablePrevious = state.pagination.currentPage === 1;
    state.pagination.disableNext =
      state.pagination.currentPage === state.pagination.totalPages;
  },
  [SET_PAGINATION_TOTALS]: (state, totalsData) => {
    state.pagination.totalResults = totalsData.results;
    state.pagination.totalPages =
      totalsData.pages > 500 ? 500 : totalsData.pages;
  },
  [SET_FILTER_MODELS_ACTIVE]: state => {
    state.filter.modelsActive = JSON.parse(JSON.stringify(state.filter.models));
  },
  [SET_FILTER_OPTIONS](state, options = {}) {
    let colorMap = {
      Black: "#000",
      White: "#FFF",
      Gray: "#8A8A8A",
      Silver:
        "linear-gradient(90deg, rgba(177,177,177,0.9) 0%, rgba(205,205,205,0.9) 50%, rgba(237,237,237,0.7) 100%)",
      Red: "#DE1616",
      Orange: "#FFA500",
      Brown: "",
      Gold:
        "linear-gradient(90deg, rgba(184,161,45,0.85) 0%, rgba(235,210,83,1) 50%, rgba(228,217,151,0.5) 100%)",
      Yellow: "#FEE12B",
      Green: "#1D9A1D",
      Blue: "#1F57C3",
      Purple: "#800080",
      Other: "#6F6F6F34"
    };

    let constructOption = (option, optionKey) => {
      let optionLabel = option.label ?? option.key;
      let optionValue = option.value ?? option.key;

      if (optionKey === "makeId" || optionKey === "make_id") {
        optionLabel = this.state.vehicleMakeModels.makeIdNameMap[optionValue];
      } else if (optionKey === "modelId" || optionKey == "model_id") {
        optionLabel = this.state.vehicleMakeModels.modelIdNameMap[optionValue];
      }

      let thisOption = {
        label: optionLabel,
        value: optionValue,
        count: mixins.methods.formatNumber(option.doc_count ?? "")
      };
      if (optionKey === "exteriorColor" || optionKey === "exterior_color") {
        thisOption.color = colorMap[thisOption.value];
      }

      return thisOption;
    };

    // Assign our standard options values (and accept ES facets formatting)
    Object.keys(options).forEach(optionKey => {
      if (
        ["price", "year", "mileage"].includes(
          mixins.methods.convertSnakeToCamel(optionKey)
        )
      ) {
        let min =
          options[optionKey].find(option => option.key === "min").doc_count ||
          0;
        let max =
          options[optionKey].find(option => option.key === "max").doc_count ||
          0;
        if (optionKey !== "year") {
          min = Math.floor(min / 1000) * 1000;
          max = Math.ceil(max / 1000) * 1000;
          if (optionKey == "mileage" && max > 200000) max = 200000;
          if (optionKey == "mileage" && min > 199000) min = 199000;
          if (optionKey == "price" && max > 150000) max = 150000;
          if (optionKey == "price" && min > 149000) min = 149000;
        }
        state.filter.options[mixins.methods.convertSnakeToCamel(optionKey)] = {
          min: min,
          max: max
        };
      } else {
        let thisOptions = [];
        let activeModelsNotEncountered = state.filter.modelsActive[
          mixins.methods.convertSnakeToCamel(optionKey)
        ].slice(0);

        options[optionKey].forEach(option => {
          let thisOption = constructOption(option, optionKey);
          thisOptions.push(thisOption);
          if (activeModelsNotEncountered.includes(thisOption.value)) {
            activeModelsNotEncountered = activeModelsNotEncountered.filter(
              value => value != thisOption.value
            );
          }
        });

        // If we still have any un-encountered active models, add them to prevent issues
        if (activeModelsNotEncountered.length > 0) {
          activeModelsNotEncountered.forEach(activeModelValue => {
            thisOptions.push(
              constructOption(
                { key: activeModelValue, doc_count: 0 },
                optionKey
              )
            );
          });
        }

        state.filter.options[
          mixins.methods.convertSnakeToCamel(optionKey)
        ] = thisOptions.sort((a, b) =>
          a.label.localeCompare(b.label, "en", { sensitivity: "base" })
        );

        if (optionKey === "makeId" || optionKey === "make_id") {
          state.filter.options.makeModelsNested = thisOptions;
        }
      }
    });

    // Set up our custom "makeModelsNested" option
    let makeModelsNestedIndexMap = {};
    state.filter.options.modelId.forEach(modelOption => {
      let thisMakeId = this.state.vehicleMakeModels.modelIdMakeIdMap[
        modelOption.value
      ];
      if (!thisMakeId) return;

      if (!([thisMakeId] in makeModelsNestedIndexMap)) {
        makeModelsNestedIndexMap[
          thisMakeId
        ] = state.filter.options.makeModelsNested.findIndex(makeOption => {
          return makeOption.value == thisMakeId;
        });
        state.filter.options.makeModelsNested[
          makeModelsNestedIndexMap[thisMakeId]
        ].modelOptions = [];
      }

      state.filter.options.makeModelsNested[
        makeModelsNestedIndexMap[thisMakeId]
      ].modelOptions.push(modelOption);
    });
  },
  [SET_SORT]: (state, sortVal) => {
    state.sort.model = sortVal;
  },
  [SET_KEYWORD]: (state, keyword) => {
    state.keyword = keyword;
  },
  [SET_LOCATION]: (state, locationObject) => {
    let cityDefault = "";
    let stateDefault = "";
    let postalCodeDefault = "";
    let radiusDefault = "100";

    // If passed in location object is not complete, fill it accordingly.
    if (typeof locationObject.city !== "string") {
      locationObject.city = cityDefault;
    }
    if (typeof locationObject.state !== "string") {
      locationObject.state = stateDefault;
    }
    if (
      typeof locationObject.postalCode !== "string" ||
      locationObject.postalCode.length !== 5
    ) {
      locationObject.postalCode = postalCodeDefault;
    }
    if (
      !locationObject.radius ||
      locationObject.radius > 500 ||
      locationObject.radius < 10
    ) {
      locationObject.radius = radiusDefault;
    }

    // If outside US, revert back to empty Nationwide search
    if (locationObject.state.length > 0) {
      let usStates = [
        "AL",
        "AK",
        "AS",
        "AZ",
        "AR",
        "CA",
        "CO",
        "CT",
        "DE",
        "DC",
        "FM",
        "FL",
        "GA",
        "GU",
        "HI",
        "ID",
        "IL",
        "IN",
        "IA",
        "KS",
        "KY",
        "LA",
        "ME",
        "MH",
        "MD",
        "MA",
        "MI",
        "MN",
        "MS",
        "MO",
        "MT",
        "NE",
        "NV",
        "NH",
        "NJ",
        "NM",
        "NY",
        "NC",
        "ND",
        "MP",
        "OH",
        "OK",
        "OR",
        "PW",
        "PA",
        "PR",
        "RI",
        "SC",
        "SD",
        "TN",
        "TX",
        "UT",
        "VT",
        "VI",
        "VA",
        "WA",
        "WV",
        "WI",
        "WY",
        "AE",
        "AA",
        "AP"
      ];
      if (!usStates.includes(locationObject.state)) {
        locationObject.city = cityDefault;
        locationObject.state = stateDefault;
        locationObject.postalCode = postalCodeDefault;
      }
    }

    state.location = JSON.parse(JSON.stringify(locationObject));

    if (locationObject.postalCode.length === 5) {
      window.localStorage.setItem(
        window.location.hostname + "-searchLocationPostalCode",
        locationObject.postalCode
      );
    }
    if (locationObject.city.length > 0) {
      window.localStorage.setItem(
        window.location.hostname + "-searchLocationCity",
        locationObject.city
      );
    }
    if (locationObject.state.length > 0) {
      window.localStorage.setItem(
        window.location.hostname + "-searchLocationState",
        locationObject.state
      );
    }
    if (locationObject.radius >= 10 && locationObject.radius <= 500) {
      window.localStorage.setItem(
        window.location.hostname + "-searchLocationRadius",
        locationObject.radius
      );
    }
  },
  [REMOVE_FILTER_MODEL_VALUE]: (state, { modelKey, value }) => {
    if (Array.isArray(state.filter.models[modelKey])) {
      let valueModelIndex = state.filter.models[modelKey].indexOf(value);
      if (valueModelIndex !== -1) {
        state.filter.modelsActive[modelKey].splice(valueModelIndex, 1);
        state.filter.models[modelKey].splice(valueModelIndex, 1);
      }
    } else {
      state.filter.modelsActive[modelKey] = "";
      state.filter.models[modelKey] = "";
    }
  },
  [RESET_PAGINATION]: state => {
    state.pagination.totalResults = 0;
    state.pagination.currentPage = 1;
    state.pagination.totalPages = 500;
    state.pagination.disablePrevious = true;
    state.pagination.disableNext = false;
  },
  [RESET_FILTERS]: (state, modelKey = "") => {
    let rangeModels = ["year", "mileage", "price"];
    let filtersToReset =
      modelKey.length > 0
        ? { [modelKey]: state.filter.modelDefaults[modelKey] }
        : state.filter.modelDefaults;
    Object.keys(filtersToReset).forEach(modelKey => {
      if (rangeModels.includes(modelKey)) {
        state.filter.modelsActive[modelKey].min =
          state.filter.modelDefaults[modelKey].min;
        state.filter.models[modelKey].min =
          state.filter.modelDefaults[modelKey].min;
        state.filter.modelsActive[modelKey].max =
          state.filter.modelDefaults[modelKey].max;
        state.filter.models[modelKey].max =
          state.filter.modelDefaults[modelKey].max;
      } else {
        if (Array.isArray(state.filter.modelDefaults[modelKey])) {
          state.filter.modelsActive[modelKey] = JSON.parse(
            JSON.stringify(state.filter.modelDefaults[modelKey])
          );
          state.filter.models[modelKey] = JSON.parse(
            JSON.stringify(state.filter.modelDefaults[modelKey])
          );
        } else {
          state.filter.modelsActive[modelKey] =
            state.filter.modelDefaults[modelKey];
          state.filter.models[modelKey] = state.filter.modelDefaults[modelKey];
        }
      }
    });
  },
  [RESET_KEYWORD]: state => {
    state.keyword = "";
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
