// import "./styles/vendor/quasar.scss";
import "@quasar/extras/roboto-font/roboto-font.css";
// import "@quasar/extras/material-icons/material-icons.css";
import "@quasar/extras/material-symbols-rounded/material-symbols-rounded.css";
import iconSet from "quasar/icon-set/material-symbols-rounded";
import Dialog from 'quasar/src/plugins/dialog/Dialog.js';import Loading from 'quasar/src/plugins/loading/Loading.js';import Dark from 'quasar/src/plugins/dark/Dark.js';;

// To be used on app.use(Quasar, { ... })
export default {
  iconSet: iconSet,
  config: {
    dark: true
  },
  plugins: {
    Dialog,
    Loading,
    Dark
  }
};
