<template>
  <div class="searchbar">
    <q-form @submit="submitSearch">
      <div class="row no-wrap bg-background">
        <q-input
          ref="SearchbarInput"
          filled
          dense
          v-model="keyword"
          :placeholder="placeholder"
          class="full-width"
          :disable="disable"
          @focus="
            () => {
              showSuggestionsDropdown = true;
            }
          "
          @blur="
            () => {
              showSuggestionsDropdown = false;
            }
          "
          debounce="300"
          @keydown="
            e => {
              if (e.keyCode == '38' || e.keyCode == '40') e.preventDefault();
            }
          "
        >
          <template v-slot:prepend>
            <q-icon
              name="sym_r_search"
              size="20px"
              class="q-mr-sm"
              style="margin-bottom: -2px"
            />
          </template>
        </q-input>
      </div>

      <transition appear name="fade" mode="in-out">
        <div
          v-show="showSuggestionsDropdown"
          class="searchbar__suggestions-container"
        >
          <div class="searchbar__suggestions-overlay" />
          <q-list class="searchbar__suggestions shadow-24">
            <div
              class="q-pa-md row no-wrap items-center --shadow__bottom bg-background-light"
              style="position: sticky; top: 0; z-index: 1"
            >
              <q-icon name="sym_r_query_stats" class="q-mr-sm" size="22px" />
              <span class="ellipsis">Suggestions</span>
            </div>
            <div
              v-show="suggestions.length === 0"
              class="q-pa-md text-text-light --text-sm"
            >
              {{
                keyword.length === 0 || pendingSuggestionsRequests.length === 0
                  ? "Start Typing..."
                  : "No suggestions."
              }}
            </div>
            <div
              v-show="suggestions.length > 0"
              @mouseleave="
                () => {
                  suggestionsActiveIndex = null;
                }
              "
            >
              <q-item
                v-for="(suggestion, i) in suggestions"
                :key="i + '_searchbarSuggestionOption'"
                v-ripple
                tag="label"
                @click="submitSearchForSuggestion(suggestion)"
                manual-focus
                :focused="suggestion.active"
                @mouseenter="
                  () => {
                    suggestionsActiveIndex = i;
                  }
                "
              >
                <q-item-section>
                  <span
                    v-html="
                      suggestion.keyword.replace(
                        new RegExp('(' + keyword + ')', 'gi'),
                        '<b class=\'text-weight-bolder text-text\'>$&</b>'
                      )
                    "
                  />
                </q-item-section>
              </q-item>
            </div>
          </q-list>
        </div>
      </transition>
    </q-form>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Searchbar",
  props: {
    disable: {
      type: Boolean,
      default: false
    },
    shortenPlaceholder: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      placeholder: "Search CarScience",
      keyword: "",
      showSuggestionsDropdown: false,
      suggestions: [],
      pendingSuggestionsRequests: [],
      suggestionsActiveIndex: null
    };
  },
  computed: {},
  watch: {
    $route() {
      this.keyword = "";
    },
    keyword(newVal) {
      if (newVal.length === 0) {
        this.suggestions = [];
      } else {
        this.fetchSuggestions();
      }
    },
    suggestionsActiveIndex(newVal) {
      this.suggestions.forEach((suggestion, i) => {
        this.suggestions[i].active = false;
      });
      if (newVal !== null) {
        this.suggestions[newVal].active = true;
      }
    }
  },
  created() {
    window.addEventListener("keydown", this.keydownHandler);
  },
  unmounted() {
    window.removeEventListener("keydown", this.keydownHandler);
  },
  mounted() {},
  methods: {
    fetchSuggestions() {
      if (this.pendingSuggestionsRequests.length > 0) {
        this.pendingSuggestionsRequests.forEach(controller => {
          controller.abort();
        });
        this.pendingSuggestionsRequests = [];
      }
      let controller = new AbortController();
      let { signal } = controller;
      this.pendingSuggestionsRequests.push(controller);

      axios({
        url:
          "https://api-v2.jumpfeed.com/network/publisher/products/vehicles/autocomplete",
        method: "POST",
        signal: signal,
        data: {
          keyword: this.keyword,
          limit: 10
        }
      }).then(response => {
        let suggestions = response.data.data.results;
        suggestions.forEach((suggestion, i) => {
          suggestions[i].active = false;
        });
        this.suggestions = suggestions;
      });
    },
    submitSearch() {
      if (this.suggestionsActiveIndex !== null) {
        this.submitSearchForSuggestion(
          this.suggestions[this.suggestionsActiveIndex]
        );
        return;
      }

      this.$emit("search");

      if (this.keyword.length === 0) {
        this.$refs.SearchbarInput.focus();
        return;
      }

      this.$store.commit("search/RESET_FILTERS");

      let keywordLowercase = this.keyword.toLowerCase();
      let keywordSearchFilterMatches = 0;

      if (keywordLowercase.length > 0) {
        Object.keys(this.$store.state.search.filter.options).every(
          optionsKey => {
            if (
              optionsKey === "exteriorColor" &&
              keywordLowercase.includes("beige")
            ) {
              this.$store.state.search.filter.models[optionsKey].push("Brown");
              keywordLowercase = keywordLowercase.replace("beige", "").trim();
              ++keywordSearchFilterMatches;
            }
            this.$store.state.search.filter.options[optionsKey].every(
              option => {
                if (option.value === "Other") return true;
                let optionValue = option.value.toString().toLowerCase();
                let optionLabel = option.label.toString().toLowerCase();
                if (
                  keywordLowercase.includes(optionValue) ||
                  keywordLowercase.includes(optionLabel)
                ) {
                  // if ((optionsKey === "engineType" || optionsKey === "bodyType") && optionValue === keyword) return true;
                  this.$store.state.search.filter.models[optionsKey].push(
                    option.value
                  );
                  keywordLowercase = keywordLowercase
                    .replace(optionValue, "")
                    .trim();
                  keywordLowercase = keywordLowercase
                    .replace(optionLabel, "")
                    .trim();
                  ++keywordSearchFilterMatches;
                }
                return true;
              }
            );
            return true;
          }
        );
      }

      if (keywordSearchFilterMatches > 0) {
        this.$store.commit("search/RESET_KEYWORD");
        this.$store.commit("search/SET_FILTER_MODELS_ACTIVE");
      } else {
        this.$store.commit("search/SET_KEYWORD", this.keyword);
      }
      this.$store.commit("search/SET_LOADING");

      this.$refs.SearchbarInput.blur();

      this.keyword = "";

      this.$store.state.search.sort.model = "advanced.popular_on_carscience";

      this.$router.push({ path: "/search" });
    },
    submitSearchForSuggestion(suggestion) {
      this.$emit("search");

      this.$store.commit("search/RESET_FILTERS");
      this.$store.commit("search/RESET_KEYWORD");

      Object.keys(suggestion.search_filters).forEach(searchFilterKey => {
        if (
          "model_id" in suggestion.search_filters &&
          searchFilterKey == "make_id"
        )
          return;
        let equalsValue = suggestion.search_filters[searchFilterKey].equals;
        if (searchFilterKey === "body_type_id") {
          let bodyTypeIdNameMap = {
            1: "Sedan",
            2: "Truck",
            3: "SUV",
            4: "Van",
            5: "Coupe",
            6: "Hatchback",
            7: "Wagon",
            8: "Convertible",
            9: "Unknown"
          };
          equalsValue = bodyTypeIdNameMap[equalsValue];
          searchFilterKey = "body_type";
        } else if (
          searchFilterKey === "make_id" ||
          searchFilterKey === "model_id"
        ) {
          equalsValue = parseInt(equalsValue);
        }
        this.$store.state.search.filter.models[
          this.convertSnakeToCamel(searchFilterKey)
        ].push(equalsValue);
      });

      this.$store.commit("search/SET_FILTER_MODELS_ACTIVE");
      this.$store.commit("search/SET_LOADING");

      this.$refs.SearchbarInput.blur();

      this.keyword = "";

      this.$store.state.search.sort.model = "advanced.popular_on_carscience";

      this.$router.push({ path: "/search" });
    },
    keydownHandler(event) {
      if (
        this.showSuggestionsDropdown &&
        this.suggestions.length > 0 &&
        (event.keyCode == "38" || event.keyCode == "40")
      ) {
        if (this.suggestionsActiveIndex == null) {
          this.suggestionsActiveIndex = 0;
        } else if (event.keyCode == "38" && this.suggestionsActiveIndex != 0) {
          --this.suggestionsActiveIndex;
        } else if (
          event.keyCode == "40" &&
          this.suggestionsActiveIndex != this.suggestions.length - 1
        ) {
          ++this.suggestionsActiveIndex;
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.searchbar {
  width: 100%;
  position: relative;

  .searchbar__suggestions-container {
    /* @include transition(all); */

    .searchbar__suggestions-overlay {
      position: fixed;
      top: $titlebar-height;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.95),
        rgba(0, 0, 0, 0.75)
      );
    }

    .searchbar__suggestions {
      position: absolute;
      top: calc(100% + 2px);
      background: color(background);
      width: 100%;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      box-shadow: $shadow-bottom;
      max-height: 80vh;
      overflow-y: auto;
      z-index: 100;
    }
  }
}
</style>
