<template>
  <header class="titlebar__container">
    <div class="titlebar__content-desktop">
      <AppLogo color="#fff" />

      <Searchbar class="q-mx-xl" style="max-width: 1025px" />

      <nav class="titlebar__nav">
        <q-tabs
          class="titlebar__nav-links"
          narrow-indicator
          indicator-color="secondary"
          dense
        >
          <q-route-tab
            v-for="(link, i) in navLinks"
            :key="i + '_titlebarNavLink'"
            class="titlebar__nav-link"
            :label="link.label"
            :to="link.route"
            :ripple="false"
            exact
          />
        </q-tabs>
      </nav>
    </div>

    <div class="titlebar__content-mobile">
      <q-btn
        flat
        round
        icon="sym_r_menu"
        class="titlebar__mobile-hamburger"
        color="white"
        :ripple="false"
        @click="toggleMobileNavMenu()"
      />
      <AppLogo color="#fff" />
      <q-btn
        flat
        round
        icon="sym_r_search"
        class="titlebar__mobile-search"
        color="white"
        :ripple="false"
        @click="toggleMobileSearchMenu()"
      />

      <q-dialog
        full-width
        v-model="mobileNavMenuActive"
        position="bottom"
        class="--fullscreen-height"
      >
        <q-card class="titlebar__mobile-nav-container">
          <nav
            class="titlebar__mobile-nav"
            :class="{ '--active': mobileNavMenuActive }"
          >
            <div class="flex no-wrap justify-between items-center q-px-lg">
              <AppLogo color="#fff" />
              <q-btn
                flat
                round
                icon="sym_r_close"
                :ripple="false"
                @click="toggleMobileNavMenu()"
                class="--icon-button"
                color="gray"
              />
            </div>

            <q-tabs
              class="titlebar__nav-links"
              indicator-color="secondary"
              vertical
            >
              <q-route-tab
                key="home_titlebarNavLink"
                class="titlebar__nav-link"
                label="Home"
                to="/"
                :ripple="false"
                exact
                @click="toggleMobileNavMenu()"
              />
              <q-route-tab
                v-for="(link, i) in navLinks"
                :key="i + '_titlebarNavLink'"
                class="titlebar__nav-link"
                :label="link.label"
                :to="link.route"
                :ripple="false"
                exact
                @click="toggleMobileNavMenu()"
              />
            </q-tabs>
          </nav>
        </q-card>
      </q-dialog>

      <q-dialog
        full-width
        v-model="mobileSearchMenuActive"
        position="bottom"
        class="--fullscreen-height"
      >
        <q-card class="titlebar__mobile-nav-container">
          <div
            class="titlebar__mobile-nav"
            :class="{ '--active': mobileSearchMenuActive }"
          >
            <div class="flex no-wrap justify-between items-center q-px-lg">
              <Searchbar
                shorten-placeholder
                class="q-mr-md"
                @search="toggleMobileSearchMenu"
              />

              <q-btn
                flat
                round
                icon="sym_r_close"
                :ripple="false"
                @click="toggleMobileSearchMenu()"
                class="--icon-button"
                color="gray"
              />
            </div>
          </div>
        </q-card>
      </q-dialog>
    </div>
  </header>
</template>

<script>
import AppLogo from "@/components/AppLogo";
import Searchbar from "@/components/Searchbar.vue";

export default {
  name: "TheTitlebar",
  components: {
    Searchbar,
    AppLogo
  },
  data() {
    return {
      mobileNavMenuActive: false,
      mobileSearchMenuActive: false,
      mobileNavSearchKeyword: "",
      navLinks: [
        /*{
          label: "Advertisers",
          route: "/advertisers"
        },*/
        /*{
          label: "Financing",
          route: "/auto-financing",
        },*/
        {
          label: "Contact Us",
          route: "/contact"
        }
      ]
    };
  },
  methods: {
    toggleMobileNavMenu() {
      this.mobileNavMenuActive = !this.mobileNavMenuActive;
    },
    toggleMobileSearchMenu() {
      this.mobileSearchMenuActive = !this.mobileSearchMenuActive;
    },
    searchKeyword() {
      this.toggleMobileSearchMenu();

      this.$router.push({
        path: "/search",
        query: { keyword: this.mobileNavSearchKeyword }
      });
      this.this.mobileNavSearchKeyword = "";
    }
  }
};
</script>

<style lang="scss" scoped>
.titlebar__container {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  height: $titlebar-height;
  background: color(background, dark);
  box-shadow: $shadow-bottom;

  .titlebar__content-desktop {
    height: 100%;
    @include display-flex(row, space-between, center);
    @include contain-content-width;
    padding: 0 custom-space(12);

    @include screen-md {
      display: none;
    }

    .titlebar__nav {
      .titlebar__nav-links {
        .titlebar__nav-link {
          color: color(text);
          font-weight: 500;
          letter-spacing: 0.25px;

          &:last-of-type {
            margin-right: 0;
          }
        }
      }
    }
  }

  .titlebar__content-mobile {
    position: relative;
    display: none;
    width: 100%;
    height: 100%;
    padding: 0 $space-lg;

    @include screen-md {
      @include display-flex(row, center, center);
    }

    .titlebar__mobile-hamburger {
      position: fixed;
      top: 8px;
      left: 8px;
      z-index: 101;
      color: color(text);
      padding: 4px;
      font-size: 1.33em;
      min-width: 2.5em;
      min-height: 2.5em;
    }
    .titlebar__mobile-search {
      position: fixed;
      top: 8px;
      right: 8px;
      z-index: 101;
      color: color(text);
      padding: 4px;
      font-size: 1.33em;
      min-width: 2.5em;
      min-height: 2.5em;
    }
  }
}

.titlebar__mobile-nav-container {
  width: 100%;
  height: 100dvh !important;
  background: color(background, dark);
  @include display-flex(column, space-between, center);
  padding-top: $space-md;

  .titlebar__mobile-nav {
    // box-shadow: -11px 0px 60px -10px rgba(0, 0, 0, 0.85);
    width: 100%;

    .titlebar__nav-links {
      .titlebar__nav-link {
        justify-content: flex-start;
        font-weight: 500;
        letter-spacing: 0.25px;
        width: 100%;
        padding: $space-md $space-lg;

        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }
}
</style>
