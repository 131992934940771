/* Actions */
// export const NAVIGATE_TO_SEARCH_PAGE = "NAVIGATE_TO_SEARCH_PAGE";
export const CHECK_FILTER_MODELS_UPDATED = "CHECK_FILTER_MODELS_UPDATED";
export const CHECK_FILTER_MODELS_DEFAULTED = "CHECK_FILTER_MODELS_DEFAULTED";
export const POPULATE_FILTER_OPTIONS = "POPULATE_FILTER_OPTIONS";

/* Mutations */
export const SET_LOADING = "SET_LOADING";
export const SET_LOADED = "SET_LOADED";
export const SET_PAGE = "SET_PAGE";
export const SET_FILTER_OPTIONS = "SET_FILTER_OPTIONS";
export const SET_PAGINATION_TOTALS = "SET_PAGINATION_TOTALS";
export const SET_STATE_FROM_QUERY_PARAM = "SET_STATE_FROM_QUERY_PARAM";
export const RESET_PAGINATION = "RESET_PAGINATION";
export const SET_SORT = "SET_SORT";
export const SET_FILTER_MODELS_FROM_KEYWORD = "SET_FILTER_MODELS_FROM_KEYWORD";
export const SET_FILTER_MODELS_ACTIVE = "SET_FILTER_MODELS_ACTIVE";
export const REMOVE_FILTER_MODEL_VALUE = "REMOVE_FILTER_MODEL_VALUE";
export const RESET_FILTERS = "RESET_FILTERS";
export const SET_URL_PARAMS = "SET_URL_PARAMS";
export const SET_KEYWORD = "SET_KEYWORD";
export const RESET_KEYWORD = "RESET_KEYWORD";
export const SET_LOCATION = "SET_LOCATION";
