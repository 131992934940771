<template>
  <footer class="footer">
    <div class="footer__content">
      <div class="footer__links">
        <router-link to="/privacy-policy">Privacy Policy</router-link>
        <router-link to="/ccpa-opt-out">Do Not Sell My Information</router-link>
        <router-link to="/terms-of-use">Terms of Use</router-link>
        <a :href="'mailto:' + $store.state.brand.contactEmail">Contact Us</a>
      </div>
      <div class="q-mt-lg text-grey">
        &copy; {{ new Date().getFullYear() }} {{ $store.state.brand.name }}. All
        Rights Reserved.
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "TheFooter"
};
</script>

<style scoped lang="scss">
.footer {
  background: color(background, dark);
  color: color(text, light);
  box-shadow: $shadow-top;

  .footer__content {
    @include contain-content-width;
    padding: $space-xl $space-lg;
    @include display-flex(column, flex-start, center);
    font-size: 0.8em;

    .footer__links {
      @include display-flex(row);
      font-weight: 500;

      @include screen-sm {
        font-size: 0.85em;
        text-align: center;
        @include display-flex(column, center, center);
      }

      a {
        margin: 0 $space-md;
        color: color(text);
        text-decoration: none;

        @include screen-sm {
          margin: 0.5rem 0.75rem !important;
        }

        &:first-of-type {
          margin-left: 0;
        }
        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }
}
</style>
