/* eslint-disable no-unused-vars */
import axios from "axios";

import {
  GET_REQUEST,
  GET_REQUEST_SUCCESS
} from "@/store/actions/vehicleMakeModels";

const state = {
  makeIdModelIdsMap: {},
  makeIdNameMap: {},
  makeNameIdMap: {},
  modelIdNameMap: {},
  modelNameIdMap: {},
  modelIdMakeIdMap: {},
  modelNameMakeNameMap: {}
};

const getters = {
  isSet: state => {
    return Object.keys(state.makeIdModelIdsMap).length > 0;
  },
  makeOptionsList: state => {
    let optionsList = [];
    for (const [makeId, make] of Object.entries(state.makeIdNameMap)) {
      optionsList.push({
        label: make,
        value: makeId
      });
    }
    optionsList.sort((a, b) => {
      return a.label > b.label ? 1 : -1;
    });
    optionsList.unshift({
      label: "All Makes",
      value: ""
    });
    return optionsList;
  }
};

const actions = {
  [GET_REQUEST]: ({ commit, getters }) => {
    return new Promise((resolve, reject) => {
      if (getters.isSet) {
        return;
      }

      axios({
        url:
          "https://api-v2.jumpfeed.com/network/publisher/products/vehicles/make-models?active=1"
      })
        .then(resp => {
          let responseData = resp.data.data;
          commit(GET_REQUEST_SUCCESS, responseData);
          resolve();
        })
        .catch(error => {
          console.log(error);
          reject();
        });
    });
  }
};

const mutations = {
  [GET_REQUEST_SUCCESS]: (state, data) => {
    state.makeIdModelIdsMap = data.make_models;

    for (const [makeId, modelIds] of Object.entries(state.makeIdModelIdsMap)) {
      modelIds.forEach(modelId => {
        state.modelIdMakeIdMap[modelId] = makeId;
      });
    }

    state.makeIdNameMap = data.make_map;

    state.makeNameIdMap = Object.keys(data.make_map).reduce((ret, key) => {
      ret[data.make_map[key]] = key;
      return ret;
    }, {});

    state.modelIdNameMap = data.model_map;
    Object.keys(state.modelIdNameMap).forEach(modelId => {
      state.modelNameIdMap[state.modelIdNameMap[modelId]] = modelId;

      let thisMakeId = state.modelIdMakeIdMap[modelId];
      state.modelNameMakeNameMap[state.modelIdNameMap[modelId]] =
        state.makeIdNameMap[thisMakeId];
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
