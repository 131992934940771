/* eslint-disable no-unused-vars */
import axios from "axios";

import {
  GET_REQUEST,
  GET_REQUEST_SUCCESS,
  VALIDATE_POSTAL_CODE
} from "@/store/actions/geolocation";

const state = {
  city: "",
  country: "",
  ipAddress: "",
  latitude: "",
  longitude: "",
  metroCode: "",
  postalCode: "",
  state: ""
};

const getters = {
  isSet: state => {
    return state.ipAddress.length > 0;
  },
  searchLocationObject: state => {
    return {
      postalCode: state.postalCode,
      city: state.city,
      state: state.state
    };
  }
};

const actions = {
  [GET_REQUEST]: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      if (state.ipAddress.length > 0) {
        resolve(true);
        return;
      }

      axios({
        method: "get",
        url: "https://d33ok5lwvgxemb.cloudfront.net/",
        mode: "no-cors",
        transformRequest: (data, headers) => {
          delete headers.common.Authorization;
          return data;
        }
      })
        .then(function(response) {
          axios({
            method: "post",
            url:
              "https://api-v2.jumpfeed.com/network/publisher/geolocation/decrypt-data",
            data: {
              geolocation_encrypted: JSON.parse(
                response.headers["viewer-geolocation"]
              ),
              logData: 0
            }
          })
            .then(function(response) {
              if (response.status === 200) {
                commit(GET_REQUEST_SUCCESS, response.data.data);
                resolve(true);
              } else {
                resolve(false);
              }
            })
            .catch(function(error) {
              console.log(error);
              reject(error);
            });
        })
        .catch(function(error) {
          console.log(error);
          reject(error);
        });
    });
  },
  [VALIDATE_POSTAL_CODE]: ({ commit, dispatch }, postalCode) => {
    return new Promise((resolve, reject) => {
      if (postalCode.length < 5) {
        reject("Invalid zip code.");
      }

      axios
        .get(
          "https://api-v2.jumpfeed.com/network/publisher/geolocation/postal-code-location-data",
          {
            params: {
              postal_code: postalCode
            }
          }
        )
        .then(response => {
          let locationData = response.data.data;
          if (
            typeof locationData["city"] === "undefined" ||
            typeof locationData["state"] === "undefined"
          ) {
            reject("Invalid zip code.");
          } else {
            resolve(locationData);
          }
        })
        .catch(() => {
          reject("Invalid zip code.");
        });
    });
  }
};

const mutations = {
  [GET_REQUEST_SUCCESS]: (state, geolocationData) => {
    state.city = geolocationData.city;
    state.country = geolocationData.country;
    state.ipAddress = geolocationData.ip_address;
    state.latitude = geolocationData.latitude;
    state.longitude = geolocationData.longitude;
    state.metroCode = geolocationData.metro_code;
    state.postalCode = geolocationData.postal_code;
    state.state = geolocationData.state;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
