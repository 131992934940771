import { createStore } from "vuex";
import publisher from "@/store/modules/publisher";
import geolocation from "@/store/modules/geolocation";
import search from "@/store/modules/search";
import vehicleMakeModels from "@/store/modules/vehicleMakeModels";

export default createStore({
  strict: false,
  state: {
    brand: {
      name: "CarScience",
      domain: "carscience.com",
      contactEmail: "support@carscience.com"
    }
  },
  mutations: {},
  actions: {},
  modules: {
    publisher,
    geolocation,
    search,
    vehicleMakeModels
  }
});
