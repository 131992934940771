/* eslint-disable no-unused-vars */
import axios from "axios";

import {
  AUTH_REQUEST,
  ATTACH_JFPC_SCRIPT,
  SET_PUBLISHER_TOKEN,
  SET_JFPC_SCRIPT_ATTACHED
} from "@/store/actions/publisher";

const state = {
  token: "",
  jfpcScriptAttached: false
};

const getters = {
  tokenIsSet: state => {
    return state.token.length > 0;
  }
};

const actions = {
  [AUTH_REQUEST]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      axios
        .post("https://api-v2.jumpfeed.com/network/publisher/auth", {
          publisher_account_id: 10001
        })
        .then(response => {
          let token = response.data.data.token;
          axios.defaults.headers.common["Authorization"] = "Bearer " + token;
          commit(SET_PUBLISHER_TOKEN, token);
          resolve(token);
        })
        .catch(error => {
          console.log(error);
          reject();
        });
    });
  },
  [ATTACH_JFPC_SCRIPT]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      let jfpcScript = document.createElement("script");
      jfpcScript.setAttribute(
        "src",
        "https://publishercomponents.jumpfeed.com/jfpc.min.js"
      );
      document.head.appendChild(jfpcScript);

      let jfpcInternalScript = document.createElement("script");
      jfpcInternalScript.setAttribute(
        "src",
        "https://publishercomponents.jumpfeed.com/internal/jfpc.min.js"
      );
      document.head.appendChild(jfpcInternalScript);

      commit(SET_JFPC_SCRIPT_ATTACHED, true);

      resolve();
    });
  }
};

const mutations = {
  [SET_PUBLISHER_TOKEN]: (state, token) => {
    window.localStorage.setItem("listings.jumpfeed.com-jwt", token); // store the publisher token in localstorage
    state.token = token;
  },
  [SET_JFPC_SCRIPT_ATTACHED]: (state, value) => {
    state.jfpcScriptAttached = value;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
